import React, { useState } from 'react';

import Dropdown from '../component.dropdown';
import useDealersData from '../../hooks/hook.useDealersData';

const CallUs = ({ title, subtitle }) => {
    // Get dealers in format required for Dropdown
    const dealersData = useDealersData();
    const dealers = [];
    dealersData.map(dealer => dealers.push({
        name: dealer.ACFDealerBlockFields.name,
        id: dealer.ACFDealerBlockFields.name,
        phone: dealer.ACFDealerBlockFields.phone,
    }));
    const initialState = dealers[0].id;
    const [activeCountry, setActiveCountry] = useState(initialState);

    return (
        <div className="c-callus o-block--bordered xs-mt-30">
            <div className="o-block">
                <h3 className="c-callus__title">{title}</h3>
                <p className="c-callus__subtitle">{subtitle}</p>
                <Dropdown
                    identifier="dealers"
                    additionalClassNames="c-dropdown--alt"
                    label="Choose a country"
                    list={dealers}
                    callback={item => setActiveCountry(item)}
                />
            </div>
            <div className="c-callus__sidebar">
                <i className="c-callus__icon fal fa-phone"></i>
                <ul className="c-callus__dealers">
                    {dealers.map(dealer => (
                        <li
                            key={dealer.name}
                            className={(activeCountry === dealer.name) ? `c-callus__dealer c-callus__dealer--visible` : `c-callus__dealer`}
                        >
                            <a href={`tel:${dealer.phone}`} className="c-callus__dealer-phone">{dealer.phone}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CallUs;
