import { useStaticQuery, graphql } from 'gatsby';

const useDealersData = () => {
    const dealersData = useStaticQuery(graphql`
        query dealersDetails {
            wordpress {
                dealers(where: { status: PUBLISH }, first: 10000) {
                    nodes {
                        ACFDealerBlockFields {
                            name: country
                            phone: phone
                        }
                    }
                }
            }
        }
    `);

    const dealers = dealersData.wordpress.dealers.nodes;
    
    // sort by dealers by country name, a to z
    dealers.sort((a, b) => {
        const dealerA = a.ACFDealerBlockFields.name;
        const dealerB = b.ACFDealerBlockFields.name;
        return ((dealerA < dealerB) ? -1 : ((dealerA > dealerB) ? 1 : 0));
    });

    return dealers;
};

export default useDealersData;
